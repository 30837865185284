<template>
    <b-container>
        <b-card>
            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <b-form-group description="Наименование">
                            <b-form-input v-model.trim="form.name" disabled />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group description="Лимит в лотках" :invalid-feedback="errors.limit[0]" :state="limitState">
                            <b-form-input v-model="form.limit" type="number" min="0" :state="limitState"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>

            <b-card-footer>
                <div class="d-flex justify-content-end">
                    <b-button @click="update" variant="success">Сохранить</b-button>
                </div>
            </b-card-footer>
        </b-card>
    </b-container>
</template>

<script>
import {AreaService} from '@services';

export default {
    name: 'Form',
    props: {
        id: {
            type: Number,
        },
    },
    data() {
        return {
            form: {
                name: null,
                limit: null,
            },
            errors: {
                limit: [],
            },
        };
    },
    computed: {
        limitState() {
            return this.errors.limit.length === 0 ? null : this.errors.limit.length < 0;
        }
    },
    methods: {
        load() {
            AreaService.getById(this.id).then((data) => {
                this.form = data;
            });
        },
        update() {
            this.errors = {
                limit: [],
            };

            if (this.form.limit < 0) {
                this.errors.limit.push('Поле должно быть не меньше 0.');
                return;
            }

            if (!this.form.limit) {
                this.errors.limit.push('Поле обязательно для заполнения.');
                return;
            }

            AreaService.update(this.id, {limit: this.form.limit}).then(() => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
            }).catch(({data}) => {
                this.errors = data.errors;
            });
        },
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    }
}
</script>
